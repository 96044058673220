import React, { useState } from 'react'
import type { ChangeHandler } from '@researchgate/react-intersection-observer/typings/types'
import Observer from '@researchgate/react-intersection-observer'

import { ViewportEnterType } from './ViewportEnterTypes'

if (typeof window !== 'undefined' && typeof window.IntersectionObserver === 'undefined') {
  require('intersection-observer') // polyfill
}

const ViewportEnter: ViewportEnterType = ({
  onEnter,
  onExit,
  once = true,
  children = <div />,
  threshold = 0.25,
  ...props
}) => {
  const [active, setActive] = useState(false)

  const onChange: ChangeHandler = (event, unobserve) => {
    const { isIntersecting } = event
    setActive(isIntersecting)
    if (isIntersecting) onEnter?.(event)
    if (!isIntersecting) onExit?.(event)
    if (once && active) unobserve()
  }

  return (
    <Observer threshold={threshold} onChange={onChange} {...props}>
      {children}
    </Observer>
  )
}

export default ViewportEnter
