import { useEffect } from 'react'

import useUIContext, { ThemeValue } from 'context/ui'

export default function useTheme(theme: ThemeValue, themeSecondary: ThemeValue) {
  const setTheme = useUIContext(s => s.setTheme)
  const setThemeSecondary = useUIContext(s => s.setThemeSecondary)

  useEffect(() => {
    setTheme(theme)
  }, [theme, setTheme])

  useEffect(() => {
    setThemeSecondary(themeSecondary)
  }, [themeSecondary, setThemeSecondary])
}
