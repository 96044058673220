import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { useLocation } from '@reach/router'

import getLocalizedData from 'lib/getLocalizedData'

import { SEOProps } from './SEOTypes'
import { defaultLanguage } from '../../../../prismic-configuration'

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allPrismicSiteSettings {
      edges {
        node {
          _previewable
          lang
          data {
            site_meta_description
            site_meta_title
            site_meta_thumbnail {
              url
              dimensions {
                height
                width
              }
              alt
            }
          }
        }
      }
    }
  }
`

const SEO = ({ title, description, thumbnail }: SEOProps) => {
  const staticData = useStaticQuery(query)
  const { data } = useMergePrismicPreviewData(staticData)
  const { pathname } = useLocation()

  const prismicSiteSettings = getLocalizedData(pathname, data.allPrismicSiteSettings)

  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = data

  const {
    data: { site_meta_description, site_meta_title, site_meta_thumbnail },
  } = prismicSiteSettings

  const baseTitle = site_meta_title
  let metaTitle

  if (!title) metaTitle = baseTitle
  else metaTitle = title === baseTitle ? title : `${site_meta_title} | ${title}`

  const metaDescription = description || site_meta_description || ''
  const metaThumbnail = thumbnail?.url || site_meta_thumbnail?.url || ''
  const metaUrl = siteUrl + pathname

  const lang = prismicSiteSettings?.lang || defaultLanguage

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaThumbnail,
        },
        {
          name: `og:image:width`,
          content: `${thumbnail?.dimensions?.width ? thumbnail.dimensions.width : ''}`,
        },
        {
          name: `og:image:height`,
          content: `${thumbnail?.dimensions?.height ? thumbnail.dimensions.height : ''}`,
        },
        {
          name: `og:image:alt`,
          content: `${thumbnail?.alt ? thumbnail.alt : ''}`,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaThumbnail,
        },
        {
          name: `viewport`,
          content: 'width=device-width, initial-scale=1.0, viewport-fit=cover',
        },
      ]}
      encodeSpecialCharacters={true}
    />
  )
}

export default SEO
